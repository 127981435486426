import React, { useState } from 'react'
import axios from 'axios'
import toast from 'react-hot-toast'

const EasyAddPixel = () => {
    const [isLoading, setIsLoading] = useState(false)

    const handleFormSubmit = async (event) => {
        event.preventDefault()
        setIsLoading(true)
        const token = sessionStorage.getItem('token')
        const userId = sessionStorage.getItem('userId')
        const listId = sessionStorage.getItem('listId')
        return axios.post('https://link-in-bio.limited/e/new', { userId:userId, listId:listId, referencingURL:'https://link-in-bio.limited/s/img/', description:' ', linkTitle:'Tracking Pixel', imgURL:null }, { headers: {authorization: token} })
        .then(async (res) => {
            const createdPixel = await axios.put(`https://link-in-bio.limited/e/replaceEntry`, { entryId:res.data.result[0].entryId, referencingURL:`https://link-in-bio.limited/s/img/${res.data.result[0].entryId}`, imgURL:null, listId:listId, linkTitle:`Tracking Pixel #${res.data.result[0].entryId}`, description:`Image link: https://link-in-bio.limited/s/img/${res.data.result[0].entryId}`}, {headers:{authorization:token}})
            setIsLoading(false)
            toast.success(`Tracking Pixel Successfully Created`)
            window.location.reload()
        })
        .catch((err) => {
            setIsLoading(false)
            toast.error('...there was a problem. Error.')
        })
    }

    return (
        <div>
            {isLoading? <p>Working...</p> :

            <div>
                <hr />
                <br />
                <h2>Create New Tracking Pixel:</h2>
                <br />
                <h3>Insert the link in the dropdown description drawer of the generated entry<br/>into an external image source tag on any website to track viewership with your Link-in.Bio/<br/>The entry and data will be visible only from your Account Dashboard.<br/>Edit the title to better remember where you placed it.</h3>
                <form onSubmit={handleFormSubmit}>
                    <button className="abutton" type="submit">Create Pixel</button>
                </form>
                <br/>
                <hr />
            </div>
            }
        </div>
    )
}

export default EasyAddPixel