import React, { useState, useEffect } from 'react'
import axios from 'axios'
import toast from 'react-hot-toast'

function CreateShortie () {
    const [selectedUrl, setSelectedUrl] = useState('åå.co')
    const [urlString, setUrlString] = useState('')
    const [referencingURL, setReferencingURL] = useState('')
    const [autoToggle, setAutoToggle] = useState(false)
    const [returnedURL, setReturnedURL] = useState('')

    const shortiedict = {
        'ää.cc':'xn--4caa.cc',
        'åå.co':'xn--5caa.co',
        'жж.cc':'xn--f1aa.cc',
        'lib.ltd':'lib.ltd',
        'link-m.ee':'link-m.ee'
    }

    const revshortiedict = {
       'xn--4caa.cc': 'ää.cc',
       'xn--5caa.co':'åå.co',
       'xn--f1aa.cc':'жж.cc',
        'lib.ltd':'lib.ltd',
        'link-m.ee':'link-m.ee'
    }

    const handleChangeRURL = (event) => {
        setReferencingURL(event.target.value)
    }

    const handleChangeSURL = (event) => {
        setSelectedUrl(event.target.value)
    }

    const handleChangeURLS = (event) => {
        setUrlString(event.target.value)
    }

    const submitCreateShortie = async (event) => {
        event.preventDefault()
        if(autoToggle){
            const createdThing = await axios.post('https://link-in-bio.limited/e/customShortie', {referencingURL:referencingURL, identifier:shortiedict[selectedUrl]+"|"+urlString})
            // console.log('ctd',createdThing.data)
            if(createdThing.data.message=='added successfully'){toast.success('Shortened URL Created Successfully'); setReturnedURL("https://"+revshortiedict[createdThing.data.id.split('|')[0]]+"/"+createdThing.data.id.split('|')[1]); setSelectedUrl('åå.co'); setUrlString(''); setReferencingURL('')}
            else if(createdThing.data.message=='not unique'){toast.error('Not Unique. Please Choose Another and Try Again.')}
            else{toast.error('Error Creating Short URL')}
        }
        else{
            const createdThing1 = await axios.post('https://link-in-bio.limited/e/shortie', {referencingURL: referencingURL, chosenURL:selectedUrl})
            // console.log('ctd',createdThing1.data)
            if(createdThing1.data.message=='added successfully'){toast.success('Shortened URL Created Successfully'); setReturnedURL(createdThing1.data.id); setSelectedUrl('åå.co'); setUrlString(''); setReferencingURL('')}
            else{toast.error('Error Creating Short URL')}
        }
    }

    return (
        <div>
            <form onSubmit={(event)=>submitCreateShortie(event)}>
                <br/>
                <label>
                    Type Your URL To Shorten Here: <br/><br/>
                    <input onChange={handleChangeRURL} value={referencingURL} name="referencingURL" type="text" required placeholder='URL To Shorten' />
                </label><br/><br/>
                <label>
                    Select The Domain for Your Link: <br/><br/>
                    <select onChange={(event)=>handleChangeSURL(event)}>
                        <option value='åå.co'>åå.co</option>
                        <option value='ää.cc'>ää.cc</option>
                        <option value='жж.cc'>жж.cc</option>
                        <option value='lib.ltd'>lib.ltd</option>
                        <option value='link-m.ee'>link-m.ee</option>
                    </select>
                </label><br/>
                <br/>
                <div style={{"cursor":"pointer", "textDecoration":"underline"}} onClick={()=>setAutoToggle(!autoToggle)}><p>{autoToggle?'Click to Autocomplete URL':'Click to Set Custom URL'}</p></div><br/>
                {autoToggle
                ?<div>
                    <p>Type your URL Here:</p>
                    <input onChange={(event)=>handleChangeURLS(event)} value={urlString} name="urlString" type='text' required placeholder='Custom Text' />
                </div> 
                :<div>
                    <p>The Service Will Choose a Short Url for You.</p>
                </div>}
                <br/>
                <button type='submit'>Create Short URL</button>
            </form>
            <br/><br/>
            {returnedURL.length>1?<p>Your Shortened URL: {returnedURL}</p>:<span></span>}
            <br/><br/>
        </div>
    )
}

export default CreateShortie