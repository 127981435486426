import React, { useRef, useState, useEffect } from 'react'
import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha'
import loadingGif from '../files/loading.gif'
import toast from 'react-hot-toast'
import {softwareLicense} from './SoftwareLicense'

function Maksaa(){
    const [isLoading, setIsLoading] = useState(false)
    const [emailAddress, setEmailAddress] = useState('')
    const [pasw, setPasw] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [referredBy, setReferredBy] = useState('organic')
    const [agreed, setAgreed] = useState(false)
    const [checkedURL, setCheckedURL] = useState(false)
    const [succesful, setSuccessful] = useState(false)
    const recapRef = useRef()
    const rpk = '6LcdDdoZAAAAAH8I2TC1gEREIep33Me2lEzwijhv'
    const hasNoIllegalChars = (value) => {
        // const stringHasSpaces = value.indexOf(' ')
        const stringHasIllegalSlash1 = value.indexOf(`\\`)
        const stringHasIllegalSlash2 = value.indexOf(`/`)
        const stringHasIllegalQuote1 = value.indexOf(`'`)
        const stringHasIllegalQuote2 = value.indexOf(`"`)
        const stringHasIllegalSemicolon = value.indexOf(`;`)
        const stringHasIllegalColon = value.indexOf(`:`) 
        const stringHasIllegalCaret = value.indexOf(`^`)
        const stringHasIllegalStar = value.indexOf(`*`)
        const stringHasIllegalHTML = value.indexOf(`<`)
        const stringHasIllegalPercent = value.indexOf('%')
        if(
            stringHasIllegalSlash1 === -1 &&
            stringHasIllegalSlash2 === -1 &&
            stringHasIllegalQuote1 === -1 &&
            stringHasIllegalQuote2 === -1 &&
            stringHasIllegalSemicolon === -1 &&
            stringHasIllegalColon === -1 &&
            stringHasIllegalCaret === -1 &&
            stringHasIllegalHTML === -1 &&
            stringHasIllegalStar === -1 &&
            stringHasIllegalPercent === -1
            // stringHasSpaces === -1 && 
        ){
            return true
        } else {
            return false
        }
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        if(agreed === true){
            setIsLoading(true)
            //verify valid email
            // console.log('haschars', hasNoIllegalChars(emailAddress))
            const validation = validate()
            if(validation !== true){
                return toast.error('Invalid Password')
            }
            if(hasNoIllegalChars(emailAddress) == true && hasNoIllegalChars(firstName) == true && hasNoIllegalChars(lastName) == true){
                const token = await recapRef.current.executeAsync()
                recapRef.current.reset()
                // console.log('token', token)
                const validEmail = await axios.post('https://link-in-bio.limited/mailer/checkValid', {email:emailAddress, token:token})
                // console.log('validEmail', validEmail)
                if(validEmail.status === 200 && validEmail.data.message === 'valid'){
                    const passthroughObj = {firstName:firstName.trim(), lastName:lastName.trim(), pw:pasw, referredBy:referredBy}
                    const passthroughString = JSON.stringify(passthroughObj)
                    const trimmedEmail = emailAddress.trim()
                    //activate paddle
                    setLastName('')
                    setFirstName('')
                    setEmailAddress('')
                    setPasw('')
                    setReferredBy('organic')
                    setIsLoading(false)
                    let bodyObject = {
                        email: trimmedEmail,
                        passthroughUser:passthroughString,
                        alert_name: 'subscription_created',
                    }
                    const returnedData = await axios.post('https://link-in-bio.limited/numbers/in2', bodyObject)
                    // console.log('returnedData', returnedData)
                    if (returnedData.status == '200'){
                        setSuccessful(true)
                        return toast.success('You may now log in!')
                    } else {
                        return toast.error('Something went wrong')
                    }
                } else {
                    return toast.error('Username already in use')
                }
            } else{
                setIsLoading(false)
                // console.log('illegalChar')
                return toast.error('You have illegal characters in your input')
            }
        } else {
            return toast.error('You must endorse the agreement to continue.')
        }
    }
    const handleChangeEmail = (evt) => {
        evt.preventDefault()
        setEmailAddress(evt.target.value)
    }
    const handleChangePasw = (evt) => {
        evt.preventDefault()
        setPasw(evt.target.value)
    }
    const handleCheckbox = (evt) => {
        // evt.preventDefault()
        setAgreed(evt.target.checked)
    }
    const handleOptionChange = (evt) => {
        evt.preventDefault()
        setReferredBy(evt.target.value)
    }
    const handleChangeFirstName = (evt) => {
        evt.preventDefault()
        setFirstName(evt.target.value)
    }
    const handleChangeLastName = (evt) => {
        evt.preventDefault()
        setLastName(evt.target.value)
    }
    const validate = () => {
        let littleerrors = []
        if(pasw.length<8){
            toast.error('Please Select a Longer Password')
            littleerrors.push(` Your Password must be 8 characters or longer. `)
        }
        if(pasw.toLowerCase() === pasw || pasw.toUpperCase() === pasw){
            toast.error('Please Use Upper and Lower Case Letters')
            littleerrors.push(` Your Password must be include upper AND lower case letters. `)
        }
        let hasNumber = false
        const split = pasw.split('')
        var i
        for(i=0; i<split.length; i++){
            let thing = parseInt(split[i],10)
            // console.log('typeofsplit[i]', typeof thing)
            if(thing !== 'NaN'){
                hasNumber = true
            }
        }
        if(hasNumber === false){
            littleerrors.push(` Your Password must include a number. `)
        }
        toast.error(JSON.stringify(littleerrors))
        if(littleerrors.length === 0){
            return true
        } else{
            return false
        }

    }

    useEffect(() => {
        if(checkedURL === false){
            if(window.location.href.indexOf('https://link-in.bio/maksaa2') !== 0 ){
                window.location.href = 'https://link-in.bio/maksaa2'
            }
        } else {
            setCheckedURL(true)
        }
    })
    
    return (<div>
        <br /><label>
        Software License Agreement: <br /> <br />
        {isLoading===true?<div><br/><img src={loadingGif} alt="Loading" /><br /></div>:<textarea disabled rows="20" cols="50" value={softwareLicense}></textarea>}
        </label>
        <br />
        <form onSubmit={handleSubmit}>
            <label>
                I read and agree to the terms : <input value={agreed} type="checkbox" onChange={handleCheckbox}/>
            </label>
            <br />
            <label>
                Your Email/Username: <input value={emailAddress} type="text" name="email" onChange={handleChangeEmail} required maxLength="125" placeholder="Username" autocomplete="username" />
            </label>
            <br />
            <label>
                <p>Your Password Must Be 8 characters long with both at least one capital letter and at least one number.</p>
                Your Password: <input value={pasw} type="password" name="pasw" onChange={handleChangePasw} required maxLength="125" placeholder="Password" autocomplete="password" />
            </label>
            <br />
            <label>
                Legal First Name: <input value={firstName} name="firstName" type="text" onChange={handleChangeFirstName} required maxLength="125" placeholder="First Name" autocomplete="given-name" />
            </label>
            <br />
            <label>
                Legal Last Name: <input value={lastName} name="lastName" type="text" onChange={handleChangeLastName} required maxLength="125" placeholder="Last Name" autocomplete="family-name"/>
            </label>
            <br />
            <label>
                How Did You Hear About Us? <br />
                <select onChange={handleOptionChange}>
                    <option value="organic">Organically</option>
                    <option value="influencer">Influencer</option>
                    <option value="media">News/Media</option>
                    <option value="meme">Meme</option>
                </select>
            </label>
            <br />
            {agreed === true ?<div><button type="submit">Create Free Account</button></div> :null}
        </form>
        {succesful === true ? <a href='https://link-in.bio/login'>Go To Login</a>:null}
        <ReCAPTCHA sitekey={rpk} size="invisible" ref={recapRef} />
    </div>)
    
}

export default Maksaa